<template>
  <div class="page-wrapper-pc">
    <div class="container">
      <div class="flex-between margin-bottom-24 padding-top-48">
        <div class="size-36 weight-600">서비스 개설 신청</div>
        <box-step :start-value="1" :end-value="2" background-color="#fafafa"></box-step>
      </div>
      <div class="service-info-box1">
        <div class="size-18 weight-600">서비스 정보</div>
        <div class="font-text2">도메인은 개설 후 변경이 가능합니다.</div>
        <input class="input weight-600 margin-top-24"
               style="width:300px;background-color: #fafafa"
               :value="`${service.key}.launchpack.co.kr`"
               disabled
               type="text"/>

        <div class="size-18 weight-600 margin-top-40 margin-bottom-32">서비스 팩</div>
        <div class="flex-between" v-for="(pack, pack_idx) in service.conversions" :key="`pack-${pack_idx}`">
          <div>·{{ pack.name }}</div>
          <div>{{ pack.price.price | currency }}</div>
        </div>

        <div class="size-18 weight-600 margin-top-40 margin-bottom-32" v-if="service.plugins && service.plugins.length>0">추가 플러그인</div>
        <div class="flex-between" v-for="(plugin, plugin_idx) in service.plugins" :key="`plugin-${plugin_idx}`">
          <div>·{{ plugin.name }}</div>
          <div>{{ plugin.price.price | currency }}</div>
        </div>

        <!--<div class="size-18 weight-600 margin-top-40 margin-bottom-32">디자인</div>
        <div class="flex-between">
          <div>·기본 디자인</div>
          <div>{{ 1100000 | currency }}</div>
        </div>-->

      </div>
      <div class="position-relative" style="padding:2px 26px;background-color:white">
        <div class="divide-dashed"></div>
        <div class="left-rotate-rectangle"></div>
        <div class="right-rotate-rectangle"></div>
      </div>
      <div class="service-info-box2">
        <div class="flex-between">
          <div class="size-18 weight-600">총 서비스 개설 금액</div>
          <div class="size-32 weight-600">{{ service.price | currency }}</div>
        </div>
      </div>

      <div style="margin-top:80px">
        <div class="size-18 weight-600">담당자 정보</div>
        <div class="font-text2">요금제 만료 14일 전 / 7일 전 / 만료 당일에 결제 담당자의 연락처로 문자 및 이메일로 안내를 드립니다.<br>
          만료 전까지 연장 결제가 이루어지지 않을 시 서비스 이용 기능이 제한되오니 유의하시기 바랍니다.</div>

        <div class="font-text2 margin-top-24">성함</div>
        <div>{{ user.name }}</div>
        <div class="font-text2 margin-top-24">연락처</div>
        <div>{{ user.phone }}</div>
        <div class="font-text2 margin-top-24">이메일</div>
        <div>{{ user.email }}</div>
      </div>

      <div style="margin-top:80px">
        <div class="size-18 weight-600">호스팅 비용 안내</div>
        <div class="font-text2">서비스를 가동하는데 필요한 서버 및 관리 비용에 해당하는 호스팅 비용이 매월 자동결제됩니다. <br>
          초기 옵션은 55,000원으로 자동 책정되며, 서비스 규모가 확장할 경우 사양을 변경할 수 있습니다.</div>

        <div class="flex-between margin-top-24">
          <div class="theme-card-wrapper" v-for="(h, host_idx) in host_list" :key="`plan-${host_idx}`">
            <div class="flex-align">
              <span class="size-20 margin-right-8 weight-500">{{ h.name }}</span>
              <span class="include-pc-st"
                    v-for="(tag, tag_idx) in h.tag" :key="`tag${tag_idx}`">{{ tag }}</span>
            </div>
            <div class="theme-content margin-top-24">{{ h.content }}</div>
            <div class="theme-price margin-top-24" style="text-align: right">
              <span v-if="h.price>0">{{ h.price | currency }}/월</span>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top:80px">
        <div class="size-18 weight-600">관리비 플랜</div>
        <div class="font-text2">안정적인 플랫폼 서비스를 운영하기 위한 유지 관리 옵션을 선택할 수 있습니다.</div>
      </div>

      <div class="card-wrapper margin-top-24 background-white">
        <div>
          <span class="size-20 weight-500 margin-right-12" style="display:inline-block;vertical-align: middle">시간당 50,000 ~ 150,000원</span>
          <span class="include-pc-st"
                v-for="(item, m_idx) in ['담당자별 비용 상이', '최소 충전금액 존재']"
                :key="`maintenance${m_idx}`">{{ item }}</span>
        </div>
        <div class="flex">
          <div class="col-6 margin-top-24">
            <div>-  오류, 버그 대응은 물론 기능 추가나 수정 모두 가능</div>
            <div style="margin-top:12px">-  매달 관리비를 납부하는 방식이 아닌 <br>
              <span style="margin-left:8px"></span>
              내 서비스에 필요한 경우에 한해서 비용 차감</div>
            <div style="margin-top:12px">-  시간당 평균 5~6만 원 수준</div>
          </div>
          <div class="col-6 flex-between padding-24" style="align-items: flex-start">
            <div class="text-center">
              <img src="@/assets/img/price_img_01.png"/>
              <div class="price-content">서비스 개설 후 <br>1개월내 무상 보수</div>
            </div>
            <div class="text-center">
              <img src="@/assets/img/price_img_02.png"/>
              <div class="price-content">서버 오류 및 버그 해결</div>
            </div>
            <div class="text-center">
              <img src="@/assets/img/price_img_03.png"/>
              <div class="price-content">추가 기능 개발</div>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="flex-between wrap margin-top-24">
        <div class="flex-align management-card" v-for="(m_cost, m_idx) in management_cost_arr"
             :key="`management-${m_idx}`">
          <div class="size-18 text-center" style="width:35%">
            <div class="weight-600">{{ m_cost.name }}</div>
            <div v-if="m_cost.sale_price>0">월{{ m_cost.sale_price | currency }}</div>
            <div class="size-15" v-else>무료</div>
            <div v-if="m_cost.origin_price>0"
                 class="size-15 origin-price-text">월{{ m_cost.origin_price | currency }}</div>
          </div>
          <div class="font-text2 size-17">
            <div style="margin:6px 0"
                 v-for="(content, c_idx) in m_cost.contents"
                 :key="`content-${c_idx}`">·<span v-html="content"></span></div>
          </div>
        </div>
      </div>-->
      <div class="flex-end" style="padding-bottom: 150px;padding-top: 100px;">
        <button class="button is-primary margin-left-12" @click="$emit('done')">다음</button>
      </div>
    </div>
  </div>
</template>
<script>

  import BoxStep from "../../component/BoxStep";
  export default {
    name: "ServiceRequestStep1",
    components: {BoxStep},
    props: {
      service: {
        type: Object
      }
    },
    created() {
    },
    data: function () {
      return {
        management_cost_arr: [
          {
            name: 'Free',
            sale_price: 0,
            origin_price: 0,
            contents: [
              '운영 솔루션 비정기적 업데이트',
              '서버 및 결제 에러 대응 <b>건별 요금 부과</b>',
              '프론트엔드 에러 대응 <b>건별 요금 부과</b>'
            ]
          },
          {
            name: 'Basic',
            sale_price: 75000,
            origin_price: 150000,
            contents: [
              '운영 솔루션 비정기적 업데이트',
              '서버 및 결제 에러 최대 <b>72시간 내 대응</b>',
              '프론트엔드 에러 최대 <b>10일 내 대응</b>'
            ]
          },
          {
            name: 'Plus',
            sale_price: 150000,
            origin_price: 300000,
            contents: [
              '운영 솔루션 비정기적 업데이트',
              '서버 및 결제 에러 최대 <b>48시간 내 대응</b>',
              '프론트엔드 에러 최대 <b>5일 내 대응</b>'
            ]
          },
          {
            name: 'Business',
            sale_price: 225000,
            origin_price: 450000,
            contents: [
              '운영 솔루션 비정기적 업데이트',
              '서버 및 결제 에러 최대 <b>24시간 내 대응</b>',
              '프론트엔드 에러 최대 <b>3일 내 대응</b>'
            ]
          },
          {
            name: 'VIP',
            sale_price: 255000,
            origin_price: 500000,
            contents: [
              '운영 솔루션 비정기적 업데이트',
              '서버 및 결제 에러 최대 <b>24시간 내 대응</b>',
              '프론트엔드 에러 최대 <b>24일 내 대응</b>'
            ]
          }
        ],
        host_list: [
          {
            "name": "Basic",
            "tag": ["추천"],
            "content": "1초 당 동시 접속 수가 100미만인 경우",
            "price": 55000
          },
          {
            "name": "Business",
            "tag": [],
            "content": "1초 당 동시 접속 수가 100이상 ~ 500미만인 경우",
            "price": 99000
          },
          {
            "name": "독립호스팅",
            "tag": ["상담 후 비용 결정"],
            "content": "1초 당 동시 접속 수가 500미만인 경우",
            "price": 0
          }
        ]
      }
    },
    methods: {}
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .divide-dashed
    border-top 2px dashed $border2

  .left-rotate-rectangle
  .right-rotate-rectangle
    width 15px
    height 15px
    background-color $background
    transform rotate(45deg)
    position absolute

  .left-rotate-rectangle
    box-shadow inset -3px 3px 4px 0 rgba(0,0,0, 0.1)
    left -8px
    top -4px

  .right-rotate-rectangle
    box-shadow inset 3px -3px 4px 0 rgba(0,0,0, 0.1)
    right -8px
    top -4px

  .service-info-box1
  .service-info-box2
    background-color white
    padding 30px 26px

  .service-info-box1
    box-shadow 0px 0px 8px 5px rgba(0,0,0, 0.1)
  .service-info-box2
    box-shadow 0px 3px 8px 3px rgba(0,0,0, 0.1)

  .management-card
    background-color white
    padding 24px
    box-shadow 0 0px 5px 4px rgba(0,0,0, 0.1)
    width 587px
    height 198px
    margin-bottom 28px
    border-radius $radius

  .origin-price-text
    color $text2
    text-decoration line-through
    text-decoration-color $primary

  .theme-card-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 32.5%;
    padding: 16px;
    border-radius:4px;
    border: 1px solid #e2e2e2;
    box-shadow: 2px 2px 6px 1px #e2e2e2;
  }
  .card-wrapper {
    box-shadow: 2px 2px 6px 1px #e2e2e2;
    padding: 16px;
  }
  .include-pc-st, .recm-st {
    color: #fd7b22;
    font-size: 12px;
    background-color: #f1f1f1;
    padding: 3px 6px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
  }
  .price-content {
    display: flex;
    align-items: center;
    height: 53px;
  }

</style>
